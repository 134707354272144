<template>
  <div>
    <!-- Delivery Area -->
    <b-button
      @click="showDeliveryAreaForm"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Delivery Area
    </b-button>

    <!-- Delivery Time -->
    <!-- <b-button
      @click="onDeliverySettingsClick"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Delivery Settings
    </b-button> -->

    <!-- Delivery Fee -->
    <b-button
      @click="onDeliveryFeeClicked"
      variant="outline-primary"
      class="mt-3"
      pill
      block
    >
      Delivery Fee
    </b-button>

    <hr />
    <b-row>
      <b-col class="d-flex justify-content-center">
        <b-button @click.prevent="back" block>
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  methods: {
    showDeliveryAreaForm() {
      this.$router.push({ name: "DeliveryAreaForm" });
    },
    onDeliveryFeeClicked() {
      // show delivery fee form
      this.$router.push({ name: "DeliveryFeeForm" });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
